<template>
  <!-- <div class="home"  id="home"> -->
  <template v-if="!isMobi">
    <div class="home">
      
      <el-carousel height="100vh" ref="carousel" :loop="false" direction="vertical" :autoplay="false"
        indicator-position="none">
        <el-carousel-item class="home-header page-option page-option-1">
          <img class="page-img" src="@/assets/b1.png" alt="">
          <div class="home-header-top" >
            <img class="home-header-top-right" src="@/assets/LOGO.png" alt="" />
            <div>
              <img class="home-header-top-left" src="@/assets/wx.png" @click="showMask = true" alt="" />
            </div>
          </div>
          <div class="home-header-bottom">
            <div class="download-ios" @mouseenter="showIos = true" @mouseleave="showIos = false">
              <img class="download-btn" src="@/assets/ios.png" alt="" />
              <img v-show="showIos" class="down-qr" src="@/assets/tc-ios.png" alt="" />
            </div>

            <div class="download-ios" @mouseenter="showAnd = true" @mouseleave="showAnd = false">
              <img class="download-btn" src="@/assets/az.png" alt="" />
              <img v-show="showAnd" class="down-qr" src="@/assets/tc-az.png" alt="" />
            </div>

          </div>
        </el-carousel-item>
        <el-carousel-item class="page-option page-option-2">
          <img class="page-img" src="@/assets/b2.png" alt="" />
        </el-carousel-item>
        <el-carousel-item class="page-option page-option-3">
          <img class="page-img" src="@/assets/b3.png" alt="" />
        </el-carousel-item>
        <el-carousel-item class="page-option page-option-4">
          <div
            style="width: 100%; height: 100%; position: relative; display: flex; flex-direction: column; align-items: center; justify-content: center">
            <div style="position: relative;width: fit-content;">
              <img class="page-img" style="width: 100%;" src="@/assets/b4.png" alt="" />
              <div class="text-group" @click="toBeian">
                <p>武汉哈哈猫科技有限公司</p>
                <p>湖北省武汉市东湖新技术开发区光谷大道58号关南福星医药园2栋5层05室</p>
                <p style="cursor: pointer"><span>营业执照</span> <span
                    @click="toBeian" class="icp">鄂ICP备2023006136号</span><span>增值电信业务经营许可证:鄂B2-20230716</span> </p>
                <p>Copyright 2024 douyu corporation,All Rights Reserved</p>
              </div>
            </div>



          </div>
        </el-carousel-item>
        <!-- </div> -->
      </el-carousel>

    </div>

    <div class="mask" v-show="showMask" @click="showMask = false">
      <img class="mask-img" src="@/assets/tc.png" alt="" />
    </div>

    <div class="mask" v-show="showBusinessLicense" @click="showBusinessLicense = false">
      <img class="mask-img-two" src="http://qiniu.douyusm.cn/frond/app/img/a1.jpg" alt="" />
    </div>
  </template>
  <template v-else>
    <div class="h_home">
      
      <van-swipe class="my-swipe" touchable vertical :loop="false" :height="height" :duration="500"
        @change="swiperChange">
        <van-swipe-item>
          <div class="item_one item_other item">
            <img width="100%" class="image_center" src="@/assets/s1.png" alt="" />
            <div class="home-header-top">
              <img class="home-header-top-right" src="@/assets/LOGO.png" alt="" />
              <div>
                <img class="home-header-top-left" src="@/assets/wx.png" @click="open()" alt="" />
              </div>
            </div>
            <div class="item_one-warp">

              <div class="downLoadBtn-box">
                
                <div class="downLoadBtn" id="downloadButton" @click="open('https://apps.apple.com/cn/app/%E5%BC%80%E5%BF%83%E5%96%B5%E4%BA%A4%E5%8F%8B/id6738041787', true)">
                  <img src="@/assets/ios.png" alt="" />
                </div>
                <div class="downLoadBtn" id="downloadButtonTwo" @click="open('https://hhm-download.oss-cn-beijing.aliyuncs.com/app/kaixinmiaoapp.apk', true)">
                  <img src="@/assets/az.png" alt="" />
                </div>
                
              </div>
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="item_other item">
            <img width="100%" class="image_center" src="@/assets/s2.png" alt="" />
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="item_other item">
            <img width="100%" class="image_center" src="@/assets/s3.png" alt="" />
          </div>
        </van-swipe-item>
        <van-swipe-item>

          <div class="scroll" ref="scrollableElement">
            <div class="item_">
              <div class="item_otherone ">

                <img width="100%" class="image_center" src="@/assets/s4.png" alt="" />
                <!-- <img src="@/assets/bottom.png" width="100%" alt="" /> -->
              </div>
              <img src="@/assets/bottom.png" width="100%" alt="" />
            </div>
            
          
          </div>


        </van-swipe-item>
        
        <template #indicator>
        </template>
      </van-swipe>
    

      <div class="more" v-if="hCurrent < 3">
        <img src="@/assets/Frame.png" class="more-icon" alt="" />
      </div>

      <van-dialog v-model:show="show" :show-confirm-button="false" width="80%" close-on-click-overlay
        @close="show = false">
        <img style="width: 100%" src="@/assets/tc.png" />
      </van-dialog>

      <div class="wx-warp" @click="showWX = false" v-show="showWX">
        <img class="wx-img" src="@/assets/Frame 4509.png" mode="scaleToFill" />
        <div class="quan">
          <img class="q-text" src="@/assets/wxtext.png" mode="scaleToFill" />
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, } from 'vue'

const isMobi = ref(/Mobi|Android/i.test(navigator.userAgent))

console.log(isMobi.value)

const current = ref(1)
const carousel = ref(null)
let height = ref(document.documentElement.clientHeight)
let div
const currentChange = (e) => {
  current.value = e
  if (carousel.value && carousel.value.setActiveItem) {
    carousel.value.setActiveItem(current.value - 1)
  }
}

const showIos = ref(false)
const showAnd = ref(false)
const showMask = ref(false)
const showBusinessLicense = ref(false)
const toBeian = () => {
  window.open('https://beian.miit.gov.cn', '_blank')
}
const openBusinessLicense = () => {
  window.open('https://file.inicoapp.com/admin_id_1623827568/image/618007c5579d000', '_blank')
}

var scrollableElement = ref(null)

function isWeixinBrowser() {
  const ua = navigator.userAgent.toLowerCase()
  return /micromessenger/.test(ua)
}
function detectDevice() {
  const userAgent = navigator.userAgent;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else {
    return 'Unknown';
  }
}

if (!isMobi.value) {
  let time
  let isStart
  window.addEventListener('wheel', (e) => {
    // console.log(time)
    if (!isStart) {
      isStart = true
      if (e.wheelDelta < 0) {
        if (current.value < 4) {
          current.value++
          if (carousel.value) {
            carousel.value.setActiveItem(current.value - 1)
          }
        }
      } else {
        if (current.value > 1) {
          current.value--
          if (carousel.value) {
            carousel.value.setActiveItem(current.value - 1)
          }
        }
      }
      setTimeout(() => {
        isStart = false
      }, 500)
    }
  })


} else {

  // onMounted(() => {
  //   const s = document.createElement("script");
  //   s.type = "text/javascript";
  //   s.src = "https://res.openinstall.com/openinstall-zlp810.js";
  //   s.addEventListener(
  //     "load",
  //     () => {
  //       var data = OpenInstall.parseUrlParams();
  //       console.log(data);
  //       new OpenInstall(
  //         {
  //           appKey: 'qxrj9x', //appkey参数配置,需要自行替换对应的appkey
  //           onready: function () {
  //             var m = this,
  //               button = detectDevice() === 'iOS' ? document.getElementById("downloadButton") : document.getElementById("downloadButtonTwo");

  //             // m.schemeWakeup();
  //             // if (!isWeixinBrowser()) {
  //             //   m.wakeupOrInstall();
  //             // }
  //             button.onclick = function () {
  //               if (isWeixinBrowser()) return (showWX.value = true);
  //               m.wakeupOrInstall();
  //               return false;
  //             };
  //           },
  //         },
  //         data
  //       );
  //     },
  //     false
  //   );
  //   document.head.appendChild(s);
  // })

}

const hCurrent = ref(0)
const show = ref(false)
const funBtnList = ref([
  {
    // icon: require('@/assets/btn1.png'),
    url: 'https://bd.douyusm.com',
  },
  {
    // icon: require('@/assets/bnt2.png'),
    url: 'https://www.douyusm.cn',
  },
  {
    // icon: require('@/assets/btn3.png'),
    url: '',
  },
])

const swiperChange = (e) => {
  // console.log(e)
  hCurrent.value = e
  if (hCurrent.value !== 3) {
    scrollableElement.value.scrollTop = 0
  }
}
const showWX = ref(false)

const open = (url, wx) => {
  if (wx) {
    const ua = navigator.userAgent.toLowerCase()
    if (/micromessenger/.test(ua)) {
      showWX.value = true
      return
    }
  }
  if (url) {
    window.location.href = url
  } else {
    console.log('没有url')
    show.value = true
  }
}
</script>

<style scoped lang="scss">
.home {
  min-width: 1260px;
  // height: 100vh;
  overflow: scroll;
  /* overflow 为 scroll，来把滚动条设置到 main 元素上 */
  // scroll-snap-type: y mandatory;
  // scroll-padding: 0px; /* 空车顶部菜单的高度 */
  // scroll-behavior: smooth; /* 添加平滑滚动效果 */
  position: relative;
}

::-webkit-scrollbar {
  display: none;
  /* 修改宽度 */
}



.page-option {
  height: 100%;
  width: 100%;
  scroll-snap-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  line-height: 24px;

  // position: relative;
  .text-group {
    position: absolute;
    color: rgb(87, 4, 87);
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .icp:hover {
      text-decoration: underline;
    }
  }

  .page-img {
    width: 80%;
  }
  
}

.page-option-2 {
  background-image: url('@/assets/BG2.png');

}

.page-option-3 {
  background-image: url('@/assets/BG3.png');

}

.page-option-4 {
  background-image: url('@/assets/BG4.png');

}

.home-header {
  height: 100%;
  width: 100%;
  background-image: url('@/assets/BG_.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scroll-snap-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .home-header-bottom {
    position: absolute;
    display: flex;
    left: 18%;
    top: 50%;

    .download-btn {
      width: 272px;

      &:first-of-type {
        margin-right: 64px;
        margin-top: 120px;
      }
    }

    .download-ios {
      position: relative;

      .down-qr {
        position: absolute;
        width: 184px;
        bottom: 0;
        left: 50%;
        transform: translateY(95%) translateX(-70%);
      }
    }
  }
}

.home-header-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 31px 18.75%;
  display: flex;
  z-index: 99;
  justify-content: space-between;
  align-items: center;

  .home-header-top-right {
    width: 120px;
    cursor: pointer;
  }

  .home-header-top-left {
    width: 104px;
    cursor: pointer;
  }
}

.page-option-bottom {
  height: 200px;
  flex-direction: column;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  line-height: 24px;
}


.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .mask-img {
    width: 420px;
  }

  .mask-img-two {
    width: 800px;
  }
}

img {
  user-select: none;
}

::v-deep .van-dialog {
  background-color: transparent;
}

.my-swipe {
  height: 100vh;

  .item {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .item_one {
    background-image: url('@/assets/S_BG.png');
    background-size: cover;
    background-position: center;

    &-warp {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      // background-color: rgba(0, 0, 0, 0.4);

      .text {
        width: 80%;
        margin-top: 68px;
      }

      .text_two {
        width: 38%;
        margin-top: 12px;
      }

      .btn-box {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .btn {
          width: 30%;
        }

        .btn_image {
          width: 100%;
        }
      }

      .downLoadBtn-box {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 6vh;
        left: 50%;
        transform: translateX(-50%);
      }

      .downLoadBtn {
        width: 148px;

        img {
          width: 100%;
        }
      }

      .downLoadBtn+.downLoadBtn {
        margin-left: 18px;
      }
    }
  }

  .item_other {
    background-image: url('../assets/S_BG.png');
    background-size: cover;
    background-position: center;
    height: 100%;
    position: relative;

    .image_center {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      .two_center {
        width: 72.5%;
        margin-top: 11px;
        margin-left: 21.5%;
      }
    }
  }
  .item_ {
    width: 100%;
    /* height: 120vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
  }
  .item_otherone {
    background-image: url('../assets/S_BG.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: relative;

    .image_center {
      height: 100vh;
      
      /* position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%); */

      
    }
  }
}

.more {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;

  img {
    width: 32px;
  }
}

.item_bottom {
  position: absolute;
  height: 100%;
  overflow-y: scroll;
}

.custom-indicator {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: rgba(198, 198, 198, 1);
    margin-bottom: 8px;
    transition: height 0.5s;
  }

  .dot-current {
    height: 22px;
    transition: height 0.5s;
    background: linear-gradient(180deg, #af6aef 0%, #885bf3 100%);
  }
}

.scroll {
  height: 100vh;
  overflow-y: auto;
}

.wx-warp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);

  .wx-img {
    position: absolute;
    right: 40px;
    width: 98px;
    height: 98px;
    top: 7px;
  }

  .quan {
    width: 199px;
    height: 91px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url('@/assets/Rectangle 3199.png');
    background-size: 100% 100%;
    position: absolute;
    right: 125px;
    top: 98px;
  }

  .q-text {
    width: 159px;
  }
}

.h_home {
  background: #000;
  .home-header-top {
    padding: 10%;

    .home-header-top-right {
      width: 65px;
    }

    .home-header-top-left {
      width: 80px;
    }
  }
}
</style>
